import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import Header from './../header';
import { db } from '../../firebase/firebase';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";

const Home = () => {
    const { currentUser } = useAuth(); // Get the current user
    const navigate = useNavigate(); // Initialize useNavigate for navigation
    const [loadingCpt, setLoadingCpt] = useState(false); // State to track loading status for CPT codes
    const [loadingIcd10, setLoadingIcd10] = useState(false); // State to track loading status for ICD-10 codes
    const [cptCompleted, setCptCompleted] = useState(false); // State to track if CPT API call is completed
    const [icd10Completed, setIcd10Completed] = useState(false); // State to track if ICD-10 API call is completed
    const [count, setCount] = useState(0);
    const [noteContent, setNoteContent] = useState(""); // State to hold textarea content
    const [selectedOption, setSelectedOption] = useState("value"); // Default selection is E/M VALUE
    const [cptCodes, setCptCodes] = useState([]); // State to store the returned CPT codes
    const [mdmLevel, setMdmLevel] = useState(""); // State to store the MDM level
    const [icd10Codes, setIcd10Codes] = useState([]); // State to store the returned ICD-10 codes

    useEffect(() => {
        if (!currentUser) {
            navigate('/login'); // Redirect to login page if user is not authenticated
        } else {
            const fetchCount = async () => {
                try {
                    const countDocRef = doc(db, "counts", currentUser.uid);
                    const countDoc = await getDoc(countDocRef);
                    if (countDoc.exists()) {
                        setCount(countDoc.data().count);
                    } else {
                        await setDoc(countDocRef, { count: 0 }); // Set count to 0 in Firestore for a new user
                        setCount(0);
                    }
                } catch (error) {
                    console.error("Failed to fetch or set the count: ", error);
                    alert("Failed to load your data, please try again.");
                }
            };
            fetchCount();
        }
    }, [currentUser, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingCpt(true);
        setLoadingIcd10(true);
        setCptCompleted(false);
        setIcd10Completed(false);
        setIcd10Codes([]);
        setMdmLevel("");
        setCptCodes([]);

        if (noteContent.length > 10000) {
            alert('Error: Note content must not exceed 10,000 characters.');
            setLoadingCpt(false);
            setLoadingIcd10(false);
            return;
        }

        if (count >= 100) {
            alert('Error: No submission credits left.');
            setLoadingCpt(false);
            setLoadingIcd10(false);
            return;
        }

        // Update the count immediately after the submit button is clicked
        if (currentUser) {
            try {
                const countDocRef = doc(db, "counts", currentUser.uid);
                await updateDoc(countDocRef, { count: count + 1 });
                setCount(count + 1);
            } catch (error) {
                console.error('Error updating count:', error);
                setLoadingCpt(false);
                setLoadingIcd10(false);
                return;
            }
        }

        try {
            const cptEndpoint = `https://prod-aizamd-routing.neurocare.ai/api/v1/note/cpt_codes/third-party/?note_content=${encodeURIComponent(noteContent)}`;
            let icd10Endpoint = '';
            if (selectedOption === "specialty") {
                icd10Endpoint = `https://prod-aizamd-routing.neurocare.ai/api/v1/note/icd10/speciality/?note_content=${encodeURIComponent(noteContent)}`;
            } else if (selectedOption === "value") {
                icd10Endpoint = `https://prod-aizamd-routing.neurocare.ai/api/v1/note/icd10/value-based/?note_content=${encodeURIComponent(noteContent)}`;
            }

            // Fetch CPT codes
            fetch(cptEndpoint, {
                method: 'GET',
                headers: { 'x-api-key': '2c102d42-3f29-4936-9b90-66f83cfa4f3b' },
            })
            .then(response => response.ok ? response.json() : Promise.reject(response))
            .then(data => {
                setCptCodes(data.cpt_code);
                setMdmLevel(data.mdm_level);
                setCptCompleted(true); // Mark CPT as completed
            })
            .catch(error => console.error('Error fetching CPT codes:', error))
            .finally(() => setLoadingCpt(false));

            // Fetch ICD-10 codes
            fetch(icd10Endpoint, {
                method: 'GET',
                headers: { 'x-api-key': '2c102d42-3f29-4936-9b90-66f83cfa4f3b' },
            })
            .then(response => response.ok ? response.json() : Promise.reject(response))
            .then(data => {
                setIcd10Codes(data);
                setIcd10Completed(true); // Mark ICD-10 as completed
            })
            .catch(error => console.error('Error fetching ICD-10 codes:', error))
            .finally(() => setLoadingIcd10(false));

        } catch (error) {
            console.error('Error during API requests:', error);
            setLoadingCpt(false);
            setLoadingIcd10(false);
            alert('An error occurred while sending the requests.');
        }
    };

    const handleNoteContentChange = (e) => {
        setNoteContent(e.target.value.replace(/<\/?[^>]+(>|$)/g, ""));
    };
   
    return (
        <>
            <Header/>
            <div className="flex flex-row justify-between p-5 h-screen">
                <div className="p-10 rounded-xl w-1/2">
                    <textarea
                        className="w-full h-full p-4 border text-black text-lg rounded-md resize-none"
                        placeholder="Enter your soap note here..."
                        value={noteContent}
                        onChange={handleNoteContentChange}
                        maxLength={10000}
                        required
                    />
                </div>
                <div className="p-5 w-1/2 text-left mt-4">
                    <select
                        className="w-full p-3 text-black bg-gray-100 text-lg rounded-md border-none"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        <option value="value">E/M - Value</option>
                        <option value="specialty">E/M - Specialty</option>
                    </select>
                    <div className="flex space-x-4 mt-5 mb-5">
                        <button
                            onClick={handleSubmit}
                            className="py-2 px-4 text-lg rounded-md bg-gray-100 border-zinc-950 text-black cursor-pointer"
                            disabled={loadingCpt || loadingIcd10 || noteContent === ""}
                        >
                            Submit
                        </button>
                     
                    </div>
                    <h3 className="text-xl font-bold">Submission Credit Left: {100 - count}</h3>
                    {loadingCpt && <div>Loading CPT codes...</div>}
                    {!loadingCpt && (
                        <>
                            <h3 className="text-xl font-bold">MDM Level: {mdmLevel}</h3>
                            <h3 className="text-xl font-bold">CPT Codes:</h3>
                            <ul className="list-disc pl-5">
                                {cptCodes.map((code, index) => (
                                    <li key={index}>
                                        <strong>{code.code}</strong>: {code.description}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {loadingIcd10 && <div>Loading ICD-10 codes...</div>}
                    {!loadingIcd10 && (
                        <>
                            <h3 className="text-xl font-bold">ICD-10 Codes:</h3>
                            <ul className="list-disc pl-5">
                                {icd10Codes.map((code, index) => (
                                    <li key={index}>
                                        <strong>{code.code}</strong>: {code.description}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Home;
