import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA9WOmaUA46v6mm5Hda-6qmkJsU3QOhxzw",
  authDomain: "medbillcode-76d7c.firebaseapp.com",
  projectId: "medbillcode-76d7c",
  storageBucket: "medbillcode-76d7c.appspot.com",
  messagingSenderId: "543978486975",
  appId: "1:543978486975:web:9eac85d716c851b00e9a0a",
  measurementId: "G-W7YCWN0P7H"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);



export { app, auth,db };
